import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';

const ProtectedRoute = ({ children }) => {
  const { userLoggedIn, currentUser } = useAuth();

  // If user is not logged in, redirect to home
  if (!userLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // If the companyId is empty, redirect to the tutorial screen
  if (currentUser && currentUser.companyId === "" || currentUser && currentUser.transactionsCollection === "") {
    return <Navigate to="/tutorial" replace />;
  }

  return children;
};

export default ProtectedRoute;
