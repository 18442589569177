import React, { Suspense, lazy, useEffect } from "react";
import { AuthProvider } from "./contexts/authContext";
import { useRoutes, useLocation } from "react-router-dom";
import ProtectedRoute from "./components/protectedRoute";

// Lazy load the components
const Login = lazy(() => import("./components/auth/login"));
const Register = lazy(() => import("./components/auth/register"));
const Home = lazy(() => import("./components/home"));
const Drivers = lazy(() => import("./components/drivers"));
const Tutorial = lazy(() => import("./components/tutorial"));

// const Samsara = lazy(() => import("./components/samsara"));
const FuelTheft = lazy(() => import("./components/fuelTheft")); // Import the Fuel Theft component
const TruckInsights = lazy(() => import("./components/truckInsights")); // Import the TruckInsights component
const Outliers = lazy(() => import ("./components/outliers") )
const CardInsights = lazy(() => import ("./components/cardInsights") )
const Samsara = lazy(() => import ("./components/samsara") )
const FuelMap = lazy(() => import ("./components/fuelMap") )
const AllTransactions = lazy(() => import ("./components/allTransactions") )




function App() {
  const location = useLocation();

  useEffect(() => {
    // Initialize Google Analytics with your tracking ID
    // Log page views on route changes with the appropriate title
  }, [location]);

  const routesArray = [
    {
      path: "*",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/home",
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
    },
    {
      path: "/drivers",
      element: (
        <ProtectedRoute>
          <Drivers />
        </ProtectedRoute>
      ),
    },
    {
      path: "/tutorial",
      element: (
        // <ProtectedRoute>
          <Tutorial />
        // </ProtectedRoute>
      ),
    },
    {
      path: "/samsara",
      element: (
        // <ProtectedRoute>
          <Samsara />
        // </ProtectedRoute>
      ),
    },
    // {
    //   path: "/fuel-map",
    //   element: (
    //     <ProtectedRoute>
    //       <FuelMap />
    //     </ProtectedRoute>
    //   ),
    // },
    {
      path: "/card-insights",
      element: (
        <ProtectedRoute>
          <CardInsights />
        </ProtectedRoute>
      ),
    },
    {
      path: "/fuel-theft",
      element: (
        <ProtectedRoute>
          <FuelTheft />
        </ProtectedRoute>
      ),
      
    },
    {
      path: "/truck-insights",
      element: (
        <ProtectedRoute>
          <TruckInsights />
        </ProtectedRoute>
      ),
    },
    {
      path: "/all-transactions",
      element: (
        <ProtectedRoute>
          <AllTransactions />
        </ProtectedRoute>
      ),
    },
    {
      path: "/outliers",
      element: (
        <ProtectedRoute>
          <Outliers />
        </ProtectedRoute>
      ),
    },
  ];

  let routesElement = useRoutes(routesArray);

  return (
    <AuthProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <div className="w-full h-screen flex flex-col">
          {routesElement}
        </div>
      </Suspense>
    </AuthProvider>
  );
}

export default App;
